import { toast } from '@/src/store/alerts';
import { Node, nodeInputRule } from '@tiptap/core';
import { Node as ProseNode } from '@tiptap/pm/model';
import { Plugin, PluginKey } from '@tiptap/pm/state';
import { EditorView } from '@tiptap/pm/view';
import { ReactNodeViewRenderer } from '@tiptap/react';
import { compareVersions } from 'compare-versions';
import EmbedComponent, { EmbedComponentProps } from './EmbedComponent';

import { v4 } from 'uuid';

export const EmbedPluginKey = new PluginKey('EmbedHandler');

const findNode = (view: EditorView, nodeId: string): FoundNode | null => {
  // deep find the node with the data-uuid attribute, if it fails we return null
  let found: FoundNode | null = null;
  view.state.doc.descendants((node, pos) => {
    if (node.attrs['data-uuid'] === nodeId && node.type.name === 'embed') {
      found = { node, pos };
    }
    if (found) return false;
  });
  return found;
};

const handleUpload = async (
  onUpload: (file: File) => Promise<string>,
  file: File,
  view: EditorView,
  pos: number,
) => {
  if (!onUpload) {
    toast({
      content: 'Upload failed.',
    });
    return;
  }

  let nodeId: string | null = null;

  try {
    // test if it's an image
    // we will use filereader to be more reliable, since sometimes mime types are not set correctly
    const isImage = await new Promise((resolve) => {
      const fileReader = new FileReader();
      fileReader.onload = (e) => {
        const image = new Image();
        image.onload = () => resolve(true);
        image.onerror = () => resolve(false);
        image.src = e.target?.result as string;
      };
      fileReader.onerror = () => resolve(false);
      fileReader.readAsDataURL(file);
    });

    if (!isImage) return;

    // since it's an image we will now create and add the node with the a temp src and loading state
    const tempSrc = URL.createObjectURL(file);

    const { schema } = view.state;

    const node = schema.nodes.embed.create({
      mimeType: file.type || 'image/*',
      src: tempSrc,
      loading: true,
      alt: file.name,
      'data-uuid': v4(),
    });

    nodeId = node.attrs['data-uuid'];

    if (!nodeId) {
      // delete the node and quit
      const transaction = view.state.tr.deleteRange(pos, pos + node.nodeSize);
      view.dispatch(transaction);
      return;
    }

    const createTransaction = view.state.tr.insert(pos, node);
    view.dispatch(createTransaction);

    // now we can upload the file to our server
    const src = await onUpload(file);

    if (!src) throw new Error('There was an error uploading the file.');

    const foundNode = findNode(view, nodeId);

    // we don't throw error here because the node could have simply been deleted
    if (!foundNode) return;

    const {
      node: { attrs },
      pos: newPos,
    } = foundNode;

    // and update the node with the new values
    const updateTransaction = view.state.tr.setNodeMarkup(
      newPos,
      node.type,
      {
        ...attrs,
        src,
        loading: false,
      },
      [],
    );

    view.dispatch(updateTransaction);
  } catch (e) {
    console.error(e);
    toast({ content: 'There was an error uploading the file.' });

    // for now if there was an error we just remove the node
    if (!nodeId) return;

    const foundNode = findNode(view, nodeId);
    if (!foundNode) return;

    const { node, pos } = foundNode;

    const transaction = view.state.tr.deleteRange(pos, pos + node.nodeSize);
    view.dispatch(transaction);
  }
};

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    embed: {
      /**
       * Add an image
       */
      setImage: (options: { src: string; alt?: string; title?: string }) => ReturnType;

      /**
       * Upload and add an image
       */
      uploadImage: (file: File) => ReturnType;

      /**
       * Will update the storage's onUpload function
       * @param onUpload
       */
      setOnUpload: (onUpload?: (file: File) => Promise<string>) => ReturnType;
    };
  }
}

type FoundNode = {
  node: ProseNode;
  pos: number;
};

const imageMarkdownRegex = /(?:^|\s)(!\[(.+|:?)]\((\S+)(?:(?:\s+)["'](\S+)["'])?\))$/;

const version = '0.0.1';
const Embed = Node.create({
  name: 'embed',

  group: 'inline',
  inline: true,
  selectable: true,
  atom: true,
  draggable: true,

  addAttributes() {
    return {
      loading: {
        default: false,

        parseHTML: (element) => {
          return element.getAttribute('data-loading') === 'true';
        },

        renderHTML: (attributes) => {
          if (attributes.loading) {
            return {
              'data-loading': attributes.loading,
            };
          }

          return {};
        },
      },
      src: {
        default: null,
      },
      alt: {
        default: null,
      },
      title: {
        default: null,
      },
      width: {
        default: null,
      },
      height: {
        default: null,
      },
      mimeType: {
        default: null,

        parseHTML: (element) => {
          const mimeType = element.getAttribute('data-mime-type');
          const version = element.getAttribute('data-version');

          // if version is not set, or below 0.0.2 there are only images
          // we might not know the specific so we just set it to
          // image/*
          if (!mimeType && (!version || compareVersions(version, '0.0.2') === -1)) {
            return 'image/*';
          }

          // if not we are screwed

          return element.getAttribute('data-mime-type');
        },
        renderHTML: (attributes) => {
          return {
            'data-mime-type': attributes.mimeType,
          };
        },
      },
      version: {
        default: version,

        parseHTML: (element) => {
          // if no version is set, we set it to the current version
          const elementVersion = element.getAttribute('data-version') || version;
          return elementVersion;
        },
        renderHTML: (attributes) => {
          return {
            'data-version': attributes.version,
          };
        },
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: 'img[src]:not(data-loading)',
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    const { 'data-loading': _dataLoading, ...attributes } = HTMLAttributes;
    // Only support images for now
    return ['img', attributes];
  },

  addCommands() {
    return {
      setImage:
        (options) =>
        ({ commands }) => {
          return commands.insertContent({
            type: this.name,
            attrs: {
              ...options,
              mimeType: 'image/*',
            },
          });
        },
      uploadImage: (file) => () => {
        // run the handleUpload function
        const pos = this.editor.state.selection.from;
        const view = this.editor.view;

        const onUpload = EmbedPluginKey.getState(view.state).onUpload;

        if (!onUpload) return false;

        handleUpload(onUpload, file, view, pos);

        return true;
      },
      setOnUpload:
        (onUpload) =>
        ({ editor }) => {
          const transaction = editor.state.tr.setMeta(EmbedPluginKey, { onUpload });
          editor.view.dispatch(transaction);
          return true;
        },
    };
  },

  addInputRules() {
    return [
      nodeInputRule({
        find: imageMarkdownRegex,
        type: this.type,
        getAttributes: (match) => {
          const [, , alt, src, title] = match;

          return { src, alt, title };
        },
      }),
    ];
  },

  addNodeView() {
    return ReactNodeViewRenderer((props: EmbedComponentProps) =>
      EmbedComponent({
        ...props,
      }),
    );
  },

  addProseMirrorPlugins() {
    const { onUpload } = this.storage;

    return [
      new Plugin({
        key: EmbedPluginKey,
        state: {
          init: () => {
            return {
              onUpload,
            };
          },
          apply: (tr, pluginState) => {
            const change = tr.getMeta(EmbedPluginKey);
            if (change !== undefined) {
              pluginState = Object.assign({}, pluginState);
              for (const key in change) {
                pluginState[key as keyof typeof pluginState] = change[key];
              }
            }
            return pluginState;
          },
        },
        props: {
          // since we have no other current drop handlers, we can just return true
          handleDOMEvents: {
            drop: (view, event) => {
              const files = event.dataTransfer?.files;

              if ((files?.length ?? 0) > 1 || (files?.length ?? 0) === 0) {
                return false;
              }

              const file = files?.[0];

              if (!file) return false;

              const onUpload = EmbedPluginKey.getState(view.state).onUpload;

              if (!onUpload) return true;

              event.preventDefault();
              event.stopPropagation();

              const coordinates = view.posAtCoords({ left: event.clientX, top: event.clientY });
              if (!coordinates) return true;

              handleUpload(onUpload, file, view, coordinates.pos);

              return true;
            },
            paste: (view, event) => {
              const files = event.clipboardData?.files;

              if ((files?.length ?? 0) > 1 || (files?.length ?? 0) === 0) {
                return false;
              }

              const file = files?.[0];

              if (!file) return false;

              const onUpload = EmbedPluginKey.getState(view.state).onUpload;

              if (!onUpload) return true;

              event.preventDefault();

              // since its a paste event, we need to get the coordinates from the selection
              const coordinates = view.posAtCoords(view.coordsAtPos(view.state.selection.from));
              if (!coordinates) return true;

              handleUpload(onUpload, file, view, coordinates.pos);
              return true;
            },
          },
        },
      }),
    ];
  },
});

export default Embed;
